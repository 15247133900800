@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --color-primary: 32 100 77;
        --color-secondary: 249 166 55;
        --color-terciary: 202 219 129;
    }
}


body {
    margin: 0;
    padding: 0;
    background-color: rgb(var(--color-primary));
    font: #ffffff;
    scroll-behavior: smooth;
}

.bg-topo {
    background-color: rgba(32, 100, 77, 0.8);
}

.banner {
    background-image: url('../imagens/background-topo.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 572px;
}

.banner-webp {
    background-image: url('../imagens/background-topo.webp');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 572px;
}

.slide>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 100vh;
}

.slide span {
    padding: 10px;
    border-radius: 5px;
    font-size: 20px;
    color: rgb(var(--color-primary));
    background: rgba(var(--color-primary), 0.5);
    text-align: center;
}

.localizacao {
    background-image: url('../imagens/Group\ 15.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 572px;
}


.video-container iframe {
    width: 80%;
    height: 45vw;
    max-height: 480px;
    margin: 0 auto;
}

#cards {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1000px;
    padding: 1em 0em;
    place-self: center;
    position: relative;
    width: calc(100% - 2em);
    z-index: 2;
}

.card {
    background-color: var(--card-color);
    border-radius: 0.75em;
    cursor: pointer;
    height: 240px;
    position: relative;
}

.card:hover:before {
    opacity: 1;
}

.card:before {
    background: linear-gradient(45deg,
            transparent 5%,
            rgb(255, 215, 137) 50%,
            transparent 99%);
    border-radius: inherit;
    content: "";
    inset: 0px;
    opacity: 0;
    position: absolute;
    transition: opacity 400ms;
    z-index: 0;
}

.card-content {
    background-color: var(--card-color);
    border-radius: inherit;
    display: grid;
    inset: 1px;
    padding: 1em;
    position: absolute;
}